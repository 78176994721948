<template>
  <v-app>
    <v-main>
    <!--   <QrScanner></QrScanner> -->
       <!-- <QrGenerator></QrGenerator> -->
      <!-- <router-view />
      <UpdateIndicator /> -->
      <QrScannerSelect ></QrScannerSelect>
      <!-- Descomentar si necesitas usar estos elementos
      <h1>hola</h1>
      <button @click="newData()">Nuevo dato</button>

      <h3 v-for="(item, index) in customers" :key="index">
        {{ item.name }}
        <button @click="deleteData(item)">delete</button>
      </h3>
      -->
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UpdateIndicator from "@/components/global/UpdateIndicator";
/* import QrScanner from "@/components/global/QrScanner.vue" */
import QrScannerSelect from "@/components/global/QrScannerSelect.vue"
/* import QrGenerator from "@/components/global/QrGenerator.vue" */

export default {
  name: "App",
  components: { UpdateIndicator,QrScannerSelect/*  QrGenerator */},
  
  data: () => ({
    // Aquí puedes añadir más datos si es necesario
  }),

  computed: {
    ...mapState({
      customers: (state) => state.customers.customers,
    }),
  },

  methods: {
    ...mapActions({
      getItems: "customers/getAll",
      saveItem: "customers/save",
      deleteItem: "customers/delete",
    }),

    newData() {
      const dt = {
        id: this.serial(),
        name: "Cesar",
      };
      this.saveItem(dt);
      this.getItems();
    },

    deleteData(item) {
      this.deleteItem(item);
      this.getItems();
    },

    serial() {
      var d = new Date().getTime();
      var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
  },
  mounted() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        // Cámara disponible; cierra la cámara si no la necesitas inmediatamente
        stream.getTracks().forEach(track => track.stop());
      })
      .catch((error) => {
        console.error('Permiso de cámara denegado:', error);
      });
  }
};
</script>
