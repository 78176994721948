<template>
  <v-row>
    <v-dialog v-model="dialog" persistent height="100vh" width="80vh">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab dark color="rgba(39,55,70,1)" fixed right bottom v-bind="attrs" v-on="on">
          <v-icon size="23" color="#D5DBDB" dark>add</v-icon>
        </v-btn>
      </template>
      <v-card color="#F2F4F4" class="white--text" v-if="dialog">
        <v-container>
          <v-card-title class="text-h5" style="color: #273746">
            Mi nuevo cliente
          </v-card-title>
          <v-card-text style="color: #273746">Datos Generales</v-card-text>

          <v-form ref="form">
            <v-text-field outlined v-model="tradename" label="Nombre Comercial" required></v-text-field>

            <v-select outlined v-model="municipality" :items="municipalityItems" label="Municipio" required></v-select>

            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="dateSuper" label="Fecha" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateSuper" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dateSuper)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-text-field outlined v-model="whoAttendedUs" label="¿Quien nos atendió?" required></v-text-field>
            <v-text-field outlined v-model="version" label="Versión" required></v-text-field>

            <v-select disabled outlined v-model="typeOfSetup" :items="typeOfSetupItems" label="Tipo de dictamen"
              required></v-select>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#273746" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn outlined color="#273746" @click="create()"> Crear </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "NewClient",
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      dateSuper: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      municipalityItems: [
        "Cozumel",
        "Isla Mujeres",
        "Benito Juárez",
        "Solidaridad",
        "Puerto Morelos",
        "Felipe Carrillo Puerto",
        "José María Morelos",
        "Lázaro Cárdenas",
        "Tulum",
        "Othón P. Blanco",
        "Bacalar",
      ],
      typeOfSetupItems: [
        "Baja tensión",
        "Media tensión",
        "Tableros",
        "Preterminado",
      ],

      modal: false,
      dialog: false,
      tradename: "",
      municipality: "",
      newDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      whoAttendedUs: "",
      version: "",
      typeOfSetup: "Preterminado",
    };
  },
  watch: {
    tradename(e) {
      this.tradename = e.toUpperCase();
    },
    whoAttendedUs(e) {
      this.whoAttendedUs = e.toUpperCase();
    },
    version(e) {
      this.version = e.toUpperCase();
    },
  },
  methods: {
    ...mapActions({
      getItems: "customers/getAll",
      saveItem: "customers/save",
      deleteItem: "customers/delete",
    }),
    create() {
      const data = {
        //id: this.serial(),
        tradename: this.tradename,
        municipality: this.municipality,
        date: this.date,
        whoAttendedUs: this.whoAttendedUs,
        version: this.version,
        typeOfSetup: this.typeOfSetup,
        generalNonconformities: "",
        //Diagrama unifilar...
        section: [
          {
            icon: true,
            tab: "description",
            content: "DocumentaryInformation",
          },
          {
            tab: "Acometida",
            content: "RushList",
          },
          {
            tab: "Subestaciones",
            content: "SubstationsList",
          },
          {
            tab: "Transformadores",
            content: "TransformersList",
          },
          {
            tab: "Tableros",
            content: "BoardList",
          },
          {
            tab: "Áreas",
            content: "AreaList",
          },
          {
            tab: "Contactos",
            content: "ContactList",
          },
          {
            tab: "Tierras",
            content: "LandList",
          },
          {
            tab: "Pararrayos",
            content: "LightningRodList",
          },
          {
            icon: true,
            tab: "notes",
            content: "GeneralNonconformities",
          },

          /*  
         Desactivada Temporalmente..
         {
            tab: "Tierras",
            content: "LandList",
          }, */
        ],
        boardList: {
          //Los items del tablero....
          config: [
            //Listo
            {
              content: "ItemBasic",
              description: "Espacio de trabajo alrededor de equipo eléctrico. ",
              nom: "110-26",
              value: null,
            },
            //Listo
            {
              content: "ItemBasicNote",
              description:
                "Ubicación con respecto a material fácilmente inflamable. ",
              nom: "408-17",
              value: null,
              note: ""
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Señales de advertencia contra arco eléctrico.",
              nom: "110-16",
              value: null,
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Identificación de los medios de desconexión.",
              nom: "110-22",
              value: null,
            },
            //Listo
            {
              content: "ItemBasicSelect",
              description:
                "Identificación de campo requerido. a) Directorio del circuito.",
              nom: "408-4",
              value: null,
              model: "No tiene directorio de circuitos.",
              items: [
                "No tiene directorio de circuitos.",
                "Debe actualizar directorio de circuitos."
              ]
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Aberturas sin utilizar.",
              nom: "408-7",
              value: null,
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Paneles.",
              nom: "408-50",
              value: null,
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Protección contra sobrecorriente.",
              nom: "408-36",
              value: null,
            },
            //Listo
            {
              content: "ItemBasicSelectModal",
              description:
                "Puesta a tierra de los tableros de alumbrado y control.",
              nom: "408-40",
              value: null,
              model: "No llega el conductor de puesta a tierra.",
              items: [
                "No llega el conductor de puesta a tierra.",
                "Conductor de puesta a tierra no conectado.",
                "Conductor de puesta a tierra no conectado y falta barra de tierras.",
                "Los conductores de puesta a tierra de circuitos derivados están empalmados."
              ]
            },
            //LIsto
            {
              content: "ItemBasicSelectModal",
              description: "Ubicación en el circuito.",
              nom: "240-21",
              value: null,
              model: "Conductores derivados sin protección en su alimentación.",
              items: [
                "Conductores derivados sin protección en su alimentación.",
                "Interruptores con más de un conductor de fase.",
                "Conductores derivados sin protección en su alimentación e Interruptores con más de un conductor de fase."
              ]
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Conexiones eléctricas. a) Terminales.",
              nom: "110-14.A",
              value: null,
            },
            //Listo
            {
              //Agregar Nuevos puntos especificos
              //punto caliente de tranformadores...
              content: "ItemThermography",
              description:
                "Conexiones eléctricas. c) Limitaciones por temperatura.",
              nom: "110-14.C",
              value: null,
              overallPhoto: "",
              itemsT: [],
              model: "Interruptor principal",
              items: [
                'Falso contacto',
                'Ampacidad',
                'Equipo dañado'
              ]
              /*  items: [
                 "Interruptor principal",
                 "Interruptor derivado",
                 "Conductor alimentador",
                 "Conductor derivado",
                 "Borne",
                 "Zapata",
                 "Barra colectora",
                 "Barra de neutros",
                 "Barra de tierras",
                 "Gabinete",
                 "Empalme, terminal",
               ], */
            },
            //Listo
            {
              //Hay que crear un nuevo componenete para item select + campo de texto
              content: "ItemBasicSelectActiveModelCheckbox",
              description: "Integridad de los equipos y de las conexiones.",
              nom: "110-12",
              value: null,
              model: "Mantenimiento preventivo",
              items: ["Mantenimiento preventivo", "Mantenimiento correctivo", "Cambio total"],
              activeModel: "Mantenimiento correctivo",
              modelValue: "Cambiar barra de tierras",
              itemsModel: [
                {
                  label: "Cambiar barra de tierras",
                  value: false,
                },
                {
                  label: "Cambiar barra de neutros",
                  value: false,
                },
                {
                  label: "Cambiar zapatas",
                  value: false,
                },
                {
                  label: "Cambiar interruptores",
                  value: false,
                },
                {
                  label: "Retirar óxido de partes dañadas",
                  value: false,
                },
                {
                  label: "Retirar partes sulfatadas",
                  value: false,
                },
                {
                  label: "Tornillería",
                  value: false,
                },
                {
                  label: "Pintura",
                  value: false,
                },
              ]
            },
            //Listo
            {
              content: "ItemBasic",
              description: "Tubo conduit de polietileno.",
              nom: "364-4",
              value: null,
            },
            //Listo
            {
              content: "ItemBasicSelectAmount",
              description: "Cables y cordones flexibles.",
              nom: "400-8",
              value: null,
              model: "Cable de uso rudo",
              items: [
                "Cable de uso rudo",
                "Cable dúplex",
                "Cable de uso rudo y dúplex",
              ],
              amount: "",
            },
            //Listo
            {
              content: "ItemBasic",
              description:
                "Continuidad mecánica de las canalizaciones y de los cables.",
              nom: "300-12",
              value: null,
            },
            {
              content: "ItemBasic",
              description: "Instalación de canalizaciones.",
              nom: "300-18",
              value: null,
            },

            {
              content: "ItemNote",
              description: "",
              nom: "Notas",
              value: null,
            },
          ],
          //Lista de los tableros..
          data: [
            /* {
              reference: "1",
              nomenclature: "ewes",
              location: "dwdsss",
            }, */
          ],
        },
        documentaryInformation: [
          {
            text: "Diagrama Unifilar",
            content: "ItemBasicSelect",
            description:
              "Debe presentar diagrama unifilar de las instalaciones eléctricas.",
            nom: "4.2.13",
            value: null,
            model: "No tiene diagrama unifilar",
            items: [
              "No tiene diagrama unifilar",
              "Diagrama unifilar no actualizado"
            ]
          },
        ],
        contactList: {
          config: {
            description: "Polaridad de las conexiones",
            nom: "200-11",
            /* value: null,
            overallPhoto: "", */
            itemsT: [],
            model: "Tierra abierta",

            items: [
              "Tierra abierta",
              "Neutro abierto",
              "Línea abierta",
              "Línea / tierra invertida",
              "Línea / neutro invertido",
              "Correcto",
            ],
          },
          data: [
            //Lista de los arrays
          ],
        },
        areaList: {
          config: {
            description: "Polaridad de las conexiones",
            nom: "200-11",
            value: null,
            overallPhoto: "",
            itemsT: [],
            model: "Tierra abierta",
            items: [
              {
                label: "Se debe retirar cable de uso rudo.",
                nom: "400-8",
                value: false,
              },
              {
                label: "Se debe retirar cable dúplex.",
                nom: "400-8",
                value: false,
              },
              {
                label: "Se debe fijar contacto adecuadamente.",
                nom: "110-12",
                value: false,
              },
              {
                label:
                  "Se debe retirar canaleta de datos e instalar tuberías eléctricas de pvc.",
                nom: "110-2",
                value: false,
              },
              {
                label: "Se debe colocar tapas a registros eléctricos.",
                nom: "314-28.C)",
                value: false,
              },
              {
                label: "Se debe reemplazar contactos dañados.",
                nom: "110-12",
                value: false,
              },
              {
                label:
                  "Se debe instalar tuberias electricas de pvc a conductores expuestos.",
                nom: "300-18",
                value: false,
              },
              {
                label: "Se debe colocar tapas a apagadores electricos.",
                nom: "300-31",
                value: false,
              },
              {
                label:
                  "Se debe reemplazar tubo poliflex por tubería eléctrica de pvc.",
                nom: "364-4",
                value: false,
              },
              {
                label:
                  "Se debe asegurar continuidad mecánica de canalizaciones.",
                nom: "300-12",
                value: false,
              },
            ],
            note: "",
          },
          data: [],
        },

        /*  landList: {
          data: [],
        }, */
        substationsList: {
          config: [
            {
              content: "ItemBasic",
              description:
                "Resguardos de locales y espacios.Los locales y espacios en que se instalen subestaciones deben tener restringido y resguardado su acceso; por medio de cercas de malla, muros o bien en locales especiales para evitar la entrada de personas no calificadas.",
              nom: "924-3",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Condiciones de los locales y espacios. b) No deben emplearse como almacenes, talleres o para otra actividad que no esté relacionada con el funcionamiento y operación del equipo.",
              nom: "924-4.B",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Condiciones de los locales y espacios. d) Deben tener ventilación adecuada para que el equipo opere a su temperatura y para minimizar los contaminantes en el aire bajo cualquier condición de operación.",
              nom: "924-4.D",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Condiciones de los locales y espacios. e) Deben mantenerse secos.",
              nom: "924-4.E",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Instalación de alumbrado. b) Circuito independiente. En subestaciones, el circuito para alumbrado y contactos debe alimentar exclusivamente estas cargas y tener protección adecuada contra sobrecorriente independiente de los otros circuitos.",
              nom: "924-5.B",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Instalación de alumbrado. e) Alumbrado de emergencia. Debe colocarse en el local, cuando menos, una lámpara para alumbrado de emergencia en cada puerta de salida del local.",
              nom: "924-5.E",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Pisos, barreras y escaleras.a) Pisos. En las subestaciones los pisos deben ser planos, firmes y con superficie antiderrapante, se debe evitar que haya obstáculos en los mismos. Los huecos, registros y trincheras deben tener tapas adecuadas.",
              nom: "924-6.A",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Accesos y salidas.Los locales y cada espacio de trabajo deben tener un acceso y salida libre de obstáculos.",
              nom: "924-7.1",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Accesos y salidas. La puerta de acceso y salida de un local debe abrir hacia afuera y estar provista de un seguro que permita su apertura, desde adentro.",
              nom: "924-7.2",
              value: null,
            },

            {
              content: "ItemBasic",
              description:
                "Accesos y salidas. Se permite el uso de puertas corredizas, siempre que éstas tengan claramente marcado su sentido de apertura y se mantengan abiertas mientras haya personas dentro del local.",
              nom: "924-7.3",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                'Accesos y salidas. La puerta debe tener fijo en la parte exterior y en forma completamente visible, un aviso con la leyenda: "PELIGRO ALTA TENSIÓN"',
              nom: "924-7.4",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Protección contra incendios. a) Extintores. Deben colocarse extintores portátiles, tantos como sean necesarios en lugares visibles, de fácil acceso, libres de obstáculos y debidamente señalizados, situando dos, cuando menos, a una distancia que no exceda de 15 metros de la entrada de las subestaciones.",
              nom: "924-8",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Partes con movimientos repentinos. Todas las partes que se mueven repentinamente y que puedan lastimar a personas que se encuentren próximas, deben protegerse por medio de resguardos.",
              nom: "924-15",
              value: null,
            },
            {
              content: "ItemBasicNote",
              description:
                "Tarimas y tapetes aislantes. En subestaciones de tipo interior, las tarimas y tapetes deben instalarse cubriendo la parte frontal de los equipos de accionamiento manual, que operen a más de 1000 volts entre conductores; su colocación no debe presentar obstáculo en la apertura de las puertas de los gabinetes.",
              nom: "924-24",
              value: null,
              note: ""
            },
          ],
          data: [
            //Lista de los arrays
          ],
        },
        transformersList: {
          config: [
            {
              //Agregar Nuevos puntos especificos
              content: "ItemThermography",
              description:
                "Conexiones eléctricas. c) Limitaciones por temperatura.",
              nom: "110-14.C",
              value: null,
              overallPhoto: "",
              itemsT: [],
              model: "",
              items: [
                'Falso contacto',
                'Ampacidad',
                'Equipo dañado'
              ]
            },
            {
              //FOTOS TERMOGRAFICAS
              content: "ItemThermographicPhotos",
              description: "",
              nom: "FOTOS TERMOGRAFICAS",
              value: null,
              overallPhoto: "",
              itemsT: [],
              model: "",
              items: [],
            },

            {
              //FUGAS DE ACEITE - CUMPLE / NO CUMPLE
              content: "ItemOilLeaks",
              description: "",
              nom: "FUGAS DE ACEITE",
              value: null,
              overallPhoto: "",
              itemsT: [],
              model: "",
              items: [],
            },

            //FOTOS TERMOGRAFICAS
            //NIVEL DE ACEITE
            //FUGAS DE ACEITE - CUMPLE / NO CUMPLE

            /*  ItemThermographicPhotos
              ItemOilLeaks */

            {
              content: "ItemBasic",
              description: "Interconexión de X0 a tierra.",
              nom: "250-36",
              value: null,
            },
            //Item con fecha..
            {
              content: "ItemBasic",
              description: "Instalación y mantenimiento del equipo eléctrico (pruebas de mantenimiento).",
              nom: "924-14",
              value: null,
              date: null,
            },
            {
              content: "ItemBasic",
              description: "Cables subterráneos. D) Identificación",
              nom: "923-3.D",
              value: null,
            },

            {
              content: "ItemBasic",
              description: "Identificación del equipo eléctrico.",
              nom: "924-16",
              value: null,
            },
            {
              content: "ItemBasic",
              description: "Marcado.",
              nom: "450-11",
              value: null,
            },
            {
              content: "ItemBasicNote",
              description: "Integridad de los equipos y de las conexiones.",
              nom: "110-12",
              value: null,
              note: ""
            },
            {
              content: "ItemNote",
              description: "",
              nom: "Notas",
              value: null,
            },

          ],
          data: [],
        },
        rushList: {
          config: [
            //1
            {
              content: "ItemBasic",
              description:
                "Equipo de recepción del suministro cubierto o resguardado. B) Resguardadas.",
              nom: "230-62.B",
              value: null,
              date: null,
            },
            //2
            {
              content: "ItemBasic",
              description:
                "Conexión de puesta a tierra de las pantallas metálicas.",
              nom: "923-3.C",
              value: null,
              date: null,
            },
            //3
            {
              content: "ItemBasicNote",
              description: "Integridad de los equipos y de las conexiones.",
              nom: "110-12",
              value: null,
              note: null,
            },
            //4
            {
              content: "ItemBasic",
              description: "Cables subterráneos. D) Identificación",
              nom: "923-3.D",
              value: null,
            },
            //5
            {
              content: "ItemBasic",
              description: "Señales de advertencia contra arco eléctrico.",
              nom: "110-16",
              value: null,
              date: null,
            },
            //6
            {
              //Agregar Nuevos puntos especificos
              content: "ItemThermography",
              description:
                "Conexiones eléctricas. c) Limitaciones por temperatura.",
              nom: "110-14.C",
              value: null,
              overallPhoto: "",
              itemsT: [],
              model: "",
              items: [
                'Falso contacto',
                'Ampacidad',
                'Equipo dañado'
              ]
            },
            //7
            {
              content: "ItemBasic",
              description: "Identificación de los medios de desconexión.",
              nom: "110-22",
              value: null,
              date: null,
            },
            //notas
            {
              content: "ItemNote",
              description: "",
              nom: "Notas",
              value: null,
            },
          ],
          data: [],
        },
        //Se agrego las variables para tierras
        landList: {
          config: [
            {
              content: "ItemLand",
              itemsT: [],
            },
          ],
          data: [],
        },
        lightningRodList: {
          config: [
            //1
            {
              content: "ItemBasic",
              description:
                "Punta.",
              nom: "1",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Mástil.",
              nom: "2",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Base.",
              nom: "3",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Retenidas.",
              nom: "4",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Tensores.",
              nom: "5",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Anclajes.",
              nom: "6",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Canalización de bajante.",
              nom: "7",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Conexiones de electrodo.",
              nom: "8",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Registro de electrodo.",
              nom: "9",
              value: null,
            },
            {
              content: "ItemBasic",
              description:
                "Separación de los conductores de bajada de los pararrayos. Las canalizaciones, envolventes, estructuras y partes metálicas de equipo eléctrico que no transporten normalmente corriente eléctrica, se deben mantener alejadas 1.80 metros como mínimo de los conductores de bajada de los electrodos de puesta a tierra de los pararrayos o deben unirse cuando la distancia a los conductores de bajada sea inferior a 1.80 metros.",
              nom: "250-46",
              value: null,
            },
            //notas
            {
              content: "ItemNote",
              description: "",
              nom: "Notas",
              value: null,
            },
          ],
          data: [],
        },
        qrList:[]
      };
      this.saveItem(data);
      this.getItems();
      this.cleanForm();
    },
    cleanForm() {
      this.dialog = false;
      this.tradename = "";
      this.municipality = null;
      this.newDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.whoAttendedUs = "";
      this.version = "";
    },
    serial() {
      var d = new Date().getTime();
      var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
  },
};
</script>
