// config.js
export default {
    version: "2.2.18",
    user: 1,
};

/*  
admin = 0
tecnico = 1 
*/


