<template>
  <v-container fluid>
    <!-- Selector de cámara con chips redondos enumerados -->
    <v-row class="my-2 chips-custom" v-if="cameras.length > 0">
      <v-chip-group v-model="selectedCameraId" mandatory>
        <v-chip
          v-for="(camera, index) in cameras"
          :key="camera.deviceId"
          :value="camera.deviceId"
          @click="changeCamera(camera.deviceId)"
          outlined
          class="ma-2"
          color="white"
        >
          {{ index + 1 }}
        </v-chip>
      </v-chip-group>
    </v-row>

    <!-- Vista de la cámara ocupando toda la pantalla -->
    <v-row>
      <v-col>
        <transition name="fade" @after-leave="onTransitionEnd">
          <video
            ref="video"
            autoplay
            playsinline
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            "
          ></video>
        </transition>
      </v-col>
    </v-row>

    <!-- Botón para reiniciar el escaneo -->
    <v-row v-if="scannedCode">
      <v-col
        class="d-flex justify-center"
        style="position: absolute; z-index: 20; top: 20%"
      >
        <v-btn @click="restartScan" color="primary">Reiniciar Escaneo</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jsQR from "jsqr";

export default {
  data() {
    return {
      cameras: [],
      selectedCameraId: null,
      stream: null,
      canvas: null,
      context: null,
      scannedCode: null, // Almacena el código QR escaneado
    };
  },
  methods: {
    async getCameras() {
      try {
        // Solicita permisos de cámara
        await navigator.mediaDevices.getUserMedia({ video: true });

        // Enumera los dispositivos de video
        const devices = await navigator.mediaDevices.enumerateDevices();

        // Filtra solo dispositivos de entrada de video
        this.cameras = devices.filter((device) => device.kind === "videoinput");

        // Selecciona la primera cámara como predeterminada
        if (this.cameras.length > 0) {
          this.selectedCameraId = this.cameras[0].deviceId;
          this.startCamera();
        } else {
          console.warn("No se encontraron cámaras.");
        }
      } catch (error) {
        console.error("Error al obtener cámaras:", error);
      }
    },
    async changeCamera(cameraId) {
      // Cambiar cámara solo si es diferente de la seleccionada
      if (this.selectedCameraId !== cameraId) {
        this.selectedCameraId = cameraId;
        this.stopCamera();
        this.startCamera();
      }
    },
    async startCamera() {
      try {
        // Detiene la transmisión actual si existe
        if (this.stream) {
          this.stopStream();
        }

        // Inicia la nueva transmisión de la cámara seleccionada
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: this.selectedCameraId } },
        });

        // Asigna la transmisión al elemento de video
        this.$refs.video.srcObject = this.stream;
        this.initCanvas();
        this.scanQRCode();
      } catch (error) {
        console.error("Error al iniciar la cámara:", error);
      }
    },
    stopStream() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    },
    initCanvas() {
      this.canvas = document.createElement("canvas");
      this.context = this.canvas.getContext("2d");
    },
    scanQRCode() {
      const drawFrame = () => {
        if (this.stream) {
          // Asegurarse de que el video esté listo y tenga dimensiones
          if (
            this.$refs.video.videoWidth > 0 &&
            this.$refs.video.videoHeight > 0
          ) {
            // Establecer dimensiones del canvas
            this.canvas.width = this.$refs.video.videoWidth;
            this.canvas.height = this.$refs.video.videoHeight;

            // Dibujar el video en el canvas
            this.context.drawImage(
              this.$refs.video,
              0,
              0,
              this.canvas.width,
              this.canvas.height
            );

            // Escanear el código QR
            const imageData = this.context.getImageData(
              0,
              0,
              this.canvas.width,
              this.canvas.height
            );
            const code = jsQR(
              imageData.data,
              this.canvas.width,
              this.canvas.height
            );

            if (code) {
              // Guardar el código escaneado
              this.scannedCode = code.data;
              alert("Código QR escaneado: " + code.data);
              this.stopCamera(); // Detener la cámara después de escanear
            } else {
              setTimeout(drawFrame, 200); // Reducir la frecuencia de análisis
            }
          } else {
            setTimeout(drawFrame, 200); // Espera si no hay dimensiones válidas
          }
        }
      };

      // Comenzar el ciclo de dibujo
      drawFrame();
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
        this.stream = null; // Limpiar la referencia del stream
      }
    },
    restartScan() {
      this.scannedCode = null; // Limpiar el código escaneado
      this.startCamera(); // Reiniciar la cámara
    },
    onTransitionEnd() {
      this.startCamera(); // Reiniciar la cámara al terminar la transición
    },
  },
  async mounted() {
    await this.getCameras();
  },
  beforeDestroy() {
    if (this.stream) {
      this.stopStream();
    }
  },
};
</script>

<style scoped>
video {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.chips-custom {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
